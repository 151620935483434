import { AccordionDetails, Alert, Button, Grid, InputLabel, TextField, styled } from "@mui/material";
import React from "react";
import { CustomerWithAdmins } from "../models/organization";
import { useMsal } from "@azure/msal-react";
import { AccessCustomerEndpointService } from "../services/access-customer-endpoint.service";
import { useTranslation } from "react-i18next";
import GtLoadingSpinner from "./spinner";
import { GtAccordion, GtAccordionSummary } from "../../../shared/components/gt-accordion";
import { DataGrid } from "@mui/x-data-grid";
import { ConfirmationDialog } from "../../../shared/components/confirmation-modal";
import use500ErrorHandling from "../../../shared/hooks/use500ErrorHandling";
import { useNotificationStore } from "../stores/notification.store";

export function CustomerAdminsView() {

    const [customerNumber, setCustomerNumber] = React.useState<string>("");
    const [selectedCustomerWithAdmins, setSelectedCustomerWithAdmins] = React.useState<CustomerWithAdmins | null>(null);
    const [isCustomerNotExists, setCustomerNotExists] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const msalConfig = useMsal();
    const { t } = useTranslation();

    const setCustomer = async () => {
        setIsLoading(true);
        const customer = await AccessCustomerEndpointService.getAdminsForCustomer(customerNumber, msalConfig);
        setCustomerNotExists(customer == null);
        setSelectedCustomerWithAdmins(customer);
        setIsLoading(false);
    }

    const isButtonDisabled = !/^(\d{6}-\d{4}|\d{10})$/.test(customerNumber); //two formats accepted 123456-1234 or 1234561234

    return (
        <React.Fragment>
            <GtAccordion>
                <GtAccordionSummary>
                    <b> {t("MonthySalaryPage.StartPage.AdminTitle")}</b>
                </GtAccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {t("MonthySalaryPage.StartPage.AdminDescription")}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} className="margin-top-10">
                        <Grid item xs={12} md={4} lg={3} xl={3}>
                            <InputLabel id="admin-input-label">{t("MonthySalaryPage.StartPage.AdminTitleSearchBox")}</InputLabel>
                            <TextField
                                fullWidth
                                size="small"
                                sx={{ backgroundColor: "white" }}
                                id="admin-input"
                                inputProps={{
                                    maxLength: 11,
                                }}
                                placeholder={t("MonthySalaryPage.StartPage.AddOrganizationNumber") ?? ""}
                                variant="outlined"
                                value={customerNumber}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setCustomerNotExists(false)
                                    setCustomerNumber(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={2} xl={2}>
                            <Button onClick={() => setCustomer()} disabled={isButtonDisabled} variant="contained" className="gt-button" style={{ marginTop: "23px" }}>{t("MonthySalaryPage.StartPage.SearchCustomer")}</Button>
                        </Grid>
                        <Grid item xs={12} className="margin-top-20">
                            {isLoading && <GtLoadingSpinner></GtLoadingSpinner>}
                            {!isLoading && selectedCustomerWithAdmins && !isCustomerNotExists &&
                                <Grid item container>
                                    <Grid item xs={12}>
                                        <div><b>{selectedCustomerWithAdmins?.organizationName}</b></div>
                                        <div><b>{selectedCustomerWithAdmins?.organizationNumber}</b></div>
                                        <div className="margin-top-10"><b>{t("MonthySalaryPage.StartPage.AdminResultTitle")}</b></div>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={5}>
                                        <AdminsTable
                                            admins={selectedCustomerWithAdmins.admins}
                                            organizationName={selectedCustomerWithAdmins.organizationName}
                                            organizationNumber={selectedCustomerWithAdmins.organizationNumber}
                                        />
                                    </Grid>
                                </Grid>
                            }
                            {!isLoading && isCustomerNotExists && <Alert severity="warning">{t("MonthySalaryPage.StartPage.NoCustomers")}</Alert>}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </GtAccordion>
        </React.Fragment>
    )
}

function AdminsTable(props: { organizationName: string, organizationNumber: string, admins: string[] }) {
    const { organizationName, organizationNumber, admins } = props;
    const msalConfig = useMsal();
    const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
    const [emailAddressToSend, setEmailAddressToSend] = React.useState<string>();
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const [setError] = use500ErrorHandling();
    const { t } = useTranslation();
    const sendSuccessMessage = useNotificationStore((state) => state.addSuccessMessage);

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        border: 0,
        fontFamily: "GT-Walsheim-Pro",
        '& .MuiDataGrid-columnHeaders': {
            color: "white",
            backgroundColor: "rgb(0, 164, 179);"
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid #ddd;`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
            borderBottom: `1px solid #ddd;`,
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
        }
    }));

    const openEmailModal = (email: string) => {
        setEmailAddressToSend(email);
        setIsModalVisible(true);
    }

    const sendEmailToUser = async () => {
        try {
            setIsProcessing(true);
            await AccessCustomerEndpointService.sendRequestEmail(
                organizationNumber,
                organizationName,
                `${document.location.origin}/salary/monthly/${organizationNumber}/access`,
                emailAddressToSend ?? "",
                msalConfig
            );
            sendSuccessMessage("sendEmail", t("MonthySalaryPage.StartPage.EmailNotification"), true, "selectOrganization")
        }
        catch (error: any) {
            setError(error);
        }
        finally {
            setIsProcessing(false);
            setIsModalVisible(false);
        }
    }

    return (
        <React.Fragment>
            <StyledDataGrid disableColumnMenu
                disableRowSelectionOnClick
                getRowId={(row) => row.email}
                style={{ backgroundColor: "white" }}
                rows={admins.map<{ email: string }>(x => { return { email: x } })}
                columns={[
                    { field: "email", headerName: t("MonthySalaryPage.StartPage.EmailHeader") ?? "", flex: 2 },
                    {
                        field: "Action", headerName: "", flex: 1,
                        renderCell: (params) => {
                            return (
                                <div style={{ textAlign: "center", width: "100%" }}>
                                    <Button onClick={() => openEmailModal(params.id as string)}>{t("MonthySalaryPage.StartPage.RequestAccessButton")}</Button>
                                </div>)
                        }
                    }
                ]}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10]}

            />
            <ConfirmationDialog
                onClose={() => setIsModalVisible(false)}
                onAccept={sendEmailToUser}
                title={t("MonthySalaryPage.StartPage.ModalTitle")}
                message={t("MonthySalaryPage.StartPage.ModalDescription").replace("{0}", emailAddressToSend ?? "")}
                isHtmlMessage={true}
                open={isModalVisible}
                isLoading={isProcessing}
            />
        </React.Fragment>
    )
}